<template>
    <div class="app-box">
        <div class="title-box">
            <div class="line"></div>
            <p class="title">设备分组</p>
        </div>
        <a-divider style="margin-left: -10px" />

        <div class="add-btn-box">
            <a-button type="primary" @click="toAdd">
                <a-icon type="plus-circle" />
                <span>添加分组</span>
            </a-button>
        </div>
        <!-- <div class="add-btn-box">
            <a-button type="primary" @click="test">
                <a-icon type="plus-circle" />
                <span>测试接口</span>
            </a-button>
        </div> -->
        <a-table class="main-table" :pagination="{
            current: query.page,
            total: pagetotal
        }" @change="listChange" :columns="columns" :data-source="data" rowKey="id" style="margin-top: 14px" v-viewer>
            <span slot="icon" slot-scope="text, record">
                <img :src="$domain + record.icon" class="img-qr">
            </span>
            <span slot="action" slot-scope="text, record">
                <a-space>
                    <a @click="toEdit(record)">编辑</a>
                    <a-popconfirm title="确认删除该分组" ok-text="是" cancel-text="否" @confirm="del(record)">
                        <a style="color: #E0352B">删除</a>
                    </a-popconfirm>
                </a-space>
            </span>
        </a-table>

        <div>
            <a-drawer :title="edit_label + '分组'" placement="right" width="650" :closable="false" :visible="visible"
                @close="onClose">
                <a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" ref="dictionaryForm" :model="form"
                    :rules="groupRules">
                    <a-form-model-item prop="name" label="名称">
                        <a-input v-model="form.name">
                        </a-input>
                    </a-form-model-item>
                    <a-form-model-item prop="remark" label="备注">
                        <a-input v-model="form.remark"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="icon" prop="icon" :wrapperCol="{ span: 19 }">
                        <a-upload :action="$domain + '/admin/v1/index/uploadImgLocal'" list-type="picture-card"
                            @change="handleHead" :show-upload-list="false" accept=".svg">
                            <img class="place-img" :src="$domain + form.icon" v-if="form.icon" />
                            <div v-else>
                                <a-icon type="plus" />
                                <div class="ant-upload-text">请上传图片</div>
                            </div>
                        </a-upload>
                    </a-form-model-item>
                    <a-form-model-item prop="groupList" label="设备类型">
                        <a-checkbox-group v-model="form.group_list">
                            <a-row>
                                <a-col :span="8" v-for="(item, index) in selectList" :key="item.dictionary_id">
                                    <a-checkbox :key="item.dictionary_id" :value="item.dictionary_id">
                                        {{ item.device_type_name }}</a-checkbox>
                                </a-col>
                            </a-row>
                        </a-checkbox-group>
                    </a-form-model-item>
                </a-form-model>
                <div :style="{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e8e8e8',
                    padding: '10px 16px',
                    textAlign: 'right',
                    left: 0,
                    background: '#fff',
                    borderRadius: '0 0 4px 4px',
                }">
                    <a-button style="marginRight: 8px" @click="onClose">
                        取消
                    </a-button>
                    <a-button type="primary" @click="submitInfo">
                        提交
                    </a-button>
                </div>
            </a-drawer>
        </div>
    </div>
</template>

<script>
import {
    domain
} from '@/siteInfo.js'
import { get } from 'js-cookie'
import { type } from 'os'

export default {
    data() {
        let rulesValidator = (rule, value, callback) => {
            if (value.length == 0) {
                callback(new Error("请选择角色权限"));
            } else {
                callback();
            }
        };
        return {
            query: {
                page: 1,
                limit: 10
            },
            group_id: null,
            visible: false,
            pagetotal: 0,
            edit_label: '',
            selectList: [],
            checkValue: [],
            checkOptions: [{
                label: '开启',
                value: 1
            },
            {
                label: '关闭',
                value: -1
            }
            ],
            columns: [{
                title: '序号',
                customRender: (text, record, index) => index + 1
            },
            {
                title: '名称',
                dataIndex: 'name',
            },
            {
                title: 'icon',
                scopedSlots: {
                    customRender: 'icon'
                }
            },
            {
                title: '备注',
                dataIndex: 'remark',
            },
            {
                title: '操作',
                scopedSlots: {
                    customRender: 'action'
                }
            }
            ],
            data: [],
            form: {
                name: '',
                remark: '',
                icon: '',
                group_list: []
            },
            confirmLoading: false,
            groupRules: {
                name: [
                    {
                        required: true,
                        message: '请输入名字',
                        trigger: 'blur'
                    }
                ],
                icon: [
                    {
                        required: true,
                        message: '请上传图片',
                        trigger: 'blur'
                    }
                ],
                group_list: [
                    {
                        validator: rulesValidator,
                        required: true,
                        message: '请选择分组',
                        trigger: 'blur'
                    }
                ]
            },
        }
    },
    created() {
        this.getList()
        this.getSelectList()
    },
    methods: {
        test() {
            this.$post(domain + '/admin/v1/test/test', {
                'ip': '10.96.255.207',
                'device_id': '53290100001320001163'
            }).then(res => {
                if (res.code === 0) {
                    this.data = res.data.list
                    this.pagetotal = res.data.total
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        toEdit(item) {
            this.edit_label = '修改'
            this.$post(domain + '/admin/v1/dictionary/groupInfo', {
                'group_id': item.group_id,
            }).then(res => {
                if (res.code === 0) {
                    this.form = res.data
                    console.log('group_id', item.group_id)
                    this.group_id = item.group_id;
                    this.visible = true;
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        del(item) {
            this.$post(domain + '/admin/v1/dictionary/groupDelete', {
                'group_id': item.group_id,
            }).then(res => {
                if (res.code === 0) {
                    this.$message.success(res.msg)
                    this.getList()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        initForm() {
            this.visible = false;
            this.form = {
                name: "",
                remark: "",
                icon: '',
                groupList: [],
            };
            this.group_id = null;
        },
        Reset() {
            this.query = {
                page: 1,
                limit: this.query.limit,
            }
            this.getList()
        },
        toAdd() {
            this.visible = true;
            this.edit_label = '添加'
        },
        toChangeList(id) {
            this.$router.push({
                path: '/changeList',
                query: {
                    id: id,
                }
            })
        },
        toContrastList(id) {
            this.$router.push({
                path: '/contrastList',
                query: {
                    id: id,
                }
            })
        },
        getList() {
            this.$post(domain + '/admin/v1/dictionary/groupList', this.query).then(res => {
                if (res.code === 0) {
                    this.data = res.data.list
                    this.pagetotal = res.data.total
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        getSelectList() {
            this.$post(domain + '/admin/v1/dictionary/select', {}).then(res => {
                if (res.code === 0) {
                    this.selectList = res.data
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        listChange(e) {
            this.query.page = e.current
            this.getList();
        },
        handleSearch(query) {
            this.getList();
        },
        handleHead(res) {
            this.uploading = true
            if (res.file.status == "done") {
                this.uploading = false
                if (res.file.response.code != 0) {
                    this.$message.error(res.file.response.msg)
                } else {
                    this.form.icon = res.file.response.data.really_path;
                }
            }
        },
        submitInfo() {
            let obj = {};
            let label = '';
            if (this.group_id != null) {
                label = 'groupUpdate',
                    obj.group_id = this.group_id

            } else {
                label = 'groupAdd'
            }
            this.$refs.dictionaryForm.validate(valid => {
                if (valid) {
                    this.confirmLoading = true
                    obj.name = this.form.name
                    obj.remark = this.form.remark
                    obj.group_list = this.form.group_list
                    obj.icon = this.form.icon
                    console.log('obj', obj)
                    this.$post("dictionary/" + label, obj)
                        .then(res => {
                            let { code, msg, data } = res
                            this.confirmLoading = false
                            if (code == 0) {
                                this.$message.success(msg, 1.5)
                                this.visible = false
                                this.getList()
                            } else {
                                this.$message.error(msg, 1.5)
                            }
                        })
                        .catch(err => {
                            this.confirmLoading = false
                        })
                }
            })
        },
        handleChange(value) {
            this.query.class_type = value
            console.log(`selected ${value}`);
        },
        onClose() {
            this.visible = false;
            this.initForm()
        },
    }
}
</script>

<style lang="less" scoped>
.img-box {
    overflow: hidden;
    display: inline-block;

    .img-icon {
        transform: translateX(-100px);
        filter: drop-shadow(blue 100px 0)
    }
}

.title-box {
    display: flex;
    align-items: center;
    margin-left: 8px;

    .line {
        width: 5px;
        height: 14px;
        background-color: #4E80F8;
    }

    .title {
        color: #383F50;
        font-size: 16px;
        margin-left: 5px;
    }
}

.img-qr {
    height: 30px;
    cursor: pointer;
}
</style>

<style lang="less">
.form-search {
    .ant-form-item-label {
        width: 70px;
    }
}
</style>
<style lang="less">
.fengmap-box {
    position: relative;
    width: 70vw;
    height: 70vh;
    overflow: hidden;

    #fengmap {
        position: absolute;
        top: 0;
        width: 100%;
        bottom: -50px;
    }
}

.feng-icon-div {
    width: 30px;
    height: 30px;
    overflow: hidden;
    background-size: 100% 100%;
    cursor: pointer;
    border-radius: 50%;
    border: 5px solid #333;
    background-color: #fff;
    box-sizing: content-box;
    margin-left: -5px;
    margin-top: -5px;

    &:hover {
        border-color: #2B6AE0;

        .img-fengmap-icon {
            filter: drop-shadow(#2B6AE0 100px 0);
        }
    }
}

.img-fengmap-icon {
    width: 24px;
    height: 24px;
    margin-left: 3px;
    margin-top: 3px;
    filter: drop-shadow(rgb(255, 0, 0) 100px 0);
    transform: translateX(-100px);
    pointer-events: none;
    user-select: none;
}

.img-fengmap-icon-green {
    width: 24px;
    height: 24px;
    margin-left: 3px;
    margin-top: 3px;
    filter: drop-shadow(rgb(2, 182, 26) 100px 0);
    transform: translateX(-100px);
    pointer-events: none;
    user-select: none;
}
</style>
